import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import SaveCard from "../../../components/payment/PaymentCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import BalanceCard from "../../../components/payment/BalanceCard";
import AutoRecharge from "../../../components/payment/AutoRecharge";
import PopupWrapper from "../../../components/utility/PopupWrapper";
import { useReChargeMutation } from "../../../api/usersAPISlices";
import {
  useDefaultPaymentMethodMutation,
  useDeletePaymentMethodsMutation,
  useGetPaymentMethodsMutation,
} from "../../../api/paymentAPISlices";
import { useGetProfileMutation } from "../../../api/authApiSlices";
import { getMeAPiCalling } from "../../../components/utility/getMeData";
import Toaster from "../../../components/utility/Toaster";
import CommonButton from "../../../components/utility/CommonButton";

import vissaLogo from "../../../assets/images/payment/vissa-card.png";
import masterLogo from "../../../assets/images/payment/master-card.png";
import AMEXLogo from "../../../assets/images/payment/amex-card.png";

const cards = [
  {
    id: 1,
    brand: "Visa",
    last4: "7830",
    exp: "06/24",
    isDefault: true,
    expired: false,
  },
  {
    id: 2,
    brand: "Visa",
    last4: "5775",
    exp: "06/24",
    isDefault: false,
    expired: false,
  },
  {
    id: 3,
    brand: "Mastercard",
    last4: "1075",
    exp: "02/25",
    isDefault: false,
    expired: false,
  },
  {
    id: 4,
    brand: "Mastercard",
    last4: "4962",
    exp: "06/24",
    isDefault: false,
    expired: true,
  },
];

const stripePromise = loadStripe(
  "pk_live_51N3iTIJ8LWo8Bg8z4e7JZ8aqdDMtVQjFc3JyVwE4Ug6zx3erpY4JYXTPg0O1rxM4f8TIjo8TivuXYGnta3FbiTnr00oBugVBN2"
);

const Payment = () => {
  const [show, setShow] = useState(false);
  const [isAddBalance, setIsAddBalance] = useState(false);
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [showToster, setShowToster] = useState(false);
  const [tosterMassage, setTosterMassage] = useState(false);
  const [tosterStatus, setTosterStatus] = useState(false);
  const [showCardDelete, setShowCardDelete] = useState(false);
  const [deleteID, setDeleteID] = useState(0);
  const [errors, setErrors] = useState();

  const [getPaymentMethods] = useGetPaymentMethodsMutation();
  const [recharge] = useReChargeMutation();
  const [defaultPaymentMethod] = useDefaultPaymentMethodMutation();
  const [deletePaymentMethods] = useDeletePaymentMethodsMutation();

  const token = JSON.parse(localStorage.getItem("token"));

  const [getProfile] = useGetProfileMutation();
  const logdenUerData = async () => {
    setIsLoading(true);
    await getMeAPiCalling(getProfile);
    setIsLoading(false);
  };

  const handleShow = () => setShow(true);

  const handleRecharge = async () => {
    setIsLoading(true);

    const payload = {
      amount: amount,
    };

    await recharge({ payload, token })
      .unwrap()
      .then((res) => {
        setErrors([]);
        setIsAddBalance(false);

        setShowToster(true);

        logdenUerData();
        setIsLoading(false);

        setTosterMassage("Amount Added");
        setTosterStatus("success");
      })
      .catch((error) => {
        setShowToster(true);
        setTosterMassage(error?.data?.message);
        setTosterStatus("error");
        setErrors(error?.data?.errors);

        setIsLoading(false);
      });
  };

  const getCardList = async () => {
    setIsCardLoading(true);
    await getPaymentMethods(token)
      .unwrap()
      .then((res) => {
        setCardList(res?.data);
        setIsCardLoading(false);
      })
      .catch((error) => {
        setIsCardLoading(false);

        setShowToster(true);
        setTosterMassage(error?.data?.message);
        setTosterStatus("error");
      });
  };

  useEffect(() => {
    logdenUerData();
  }, []);

  useEffect(() => {
    // setTimeout(() => {
      getCardList();
    // }, 1000);
  }, [show]);

  const handleDefaultPaymentMethod = async (Id) => {
    setIsCardLoading(true);

    await defaultPaymentMethod({ Id, token })
      .unwrap()
      .then((res) => {
        getCardList();

        setShowToster(true);
        setTosterMassage("successfully saved");
        setTosterStatus("success");
      })
      .catch((error) => {
        setIsCardLoading(false);

        setShowToster(true);
        setTosterMassage(error?.data?.message);
        setTosterStatus("error");
      });
  };

  const handleDeleteID = async (Id) => {
    setDeleteID(Id);
    setShowCardDelete(true);
  };

  const handleDeletePaymentMethods = async (Id) => {
    setIsCardLoading(true);

    await deletePaymentMethods({ Id, token })
      .unwrap()
      .then((res) => {
        getCardList();

        setShowToster(true);
        setTosterMassage("Successfully Deleted");
        setTosterStatus("success");
      })
      .catch((error) => {
        setIsCardLoading(false);

        setShowToster(true);
        setTosterMassage(error?.data?.message);
        setTosterStatus("error");
      });
  };

  return (
    <Container fluid>
      <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="font-weight-bold">Billing</h4>
        </div>
        <div className="create-workform">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="modal-product-search d-flex">
              {cardList?.length > 0 && (
                <Button
                  onClick={() => setIsAddBalance(true)}
                  className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  Add Balance
                </Button>
              )}
              <Button
                onClick={handleShow}
                className="btn btn-primary position-relative d-flex align-items-center justify-content-between ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                  width="20"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                Add Card
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Row style={{ maxWidth: "1000px", margin: "auto" }}>
        <Col md="5" style={{ marginBottom: "10px" }}>
          <BalanceCard isLoading={isLoading} />
          {isLoading ? (
            <div className="text-center h-6 mt-5">
              <div
                className="spinner-border text-warning loadding-spinner mr-2"
                style={{
                  opacity: isLoading ? 100 : 0,
                }}
                role="status"
              >
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : 
            cardList?.length > 0 && <AutoRecharge isLoading={isLoading} setIsLoading={setIsLoading} />
          }
        </Col>

        {/* Card List */}
        <Col md="7">
          {!isCardLoading ? (
            cardList?.length > 0 ? (
              cardList?.map((card) => (
                <Card
                  key={card.id}
                  className="mb-3 shadow-sm position-relative"
                >
                  <Card.Body>
                    <Row className="align-items-center justify-content-between">
                      {/* Card Details */}
                      <Col xs={3}>
                        <Image
                          style={{ maxWidth: "100%" }}
                          src={
                            card?.card_brand === "visa"
                              ? vissaLogo
                              : card?.card_brand === "master"
                              ? vissaLogo
                              : vissaLogo
                          }
                          alt=""
                        />
                      </Col>

                      <Col xs={5}>
                        <h6 className="mb-1">
                          {card?.card_brand} ending in {card?.card_last4}
                        </h6>
                        <small className="text-muted">
                          Exp. date {card?.exp_month}/{card?.exp_year}
                        </small>
                      </Col>

                      {/* Actions */}
                      <Col xs={4} className="text-end">
                        <span>
                          {card?.is_default ? (
                            <Badge bg="dark" className="px-2 text-black">
                              Default
                            </Badge>
                          ) : (
                            <Button
                              bg="dark"
                              className=" mr-2 p-0 px-1"
                              onClick={() =>
                                handleDefaultPaymentMethod(card?.id)
                              }
                            >
                              set Default
                            </Button>
                          )}
                        </span>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <Button
                            className="badge bg-danger border-0 px-2 py-1 pb-2"
                            onClick={() => handleDeleteID(card?.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p className="text-center">Please Add a Payment Method</p>
            )
          ) : (
            <div
              className="d-flex justify-content-center pb-5 mt-5"
              style={{ width: "100%" }}
            >
              <div
                className="spinner-border text-warning m-auto mr-2"
                role="status"
              >
                <span className="visually-hidden"></span>
              </div>
            </div>
          )}
        </Col>

        <PopupWrapper
          title="Enter Your Card Details"
          show={show}
          setShow={setShow}
        >
          <Elements stripe={stripePromise}>
            <SaveCard setShow={setShow} getCardList={getCardList} />
          </Elements>
        </PopupWrapper>

        <PopupWrapper
          title="Add Balance"
          show={isAddBalance}
          setShow={setIsAddBalance}
        >
          <Form.Group>
            <Form.Label className="font-weight-bold">Amount:</Form.Label>
            <Form.Control
              type="amount"
              placeholder="Enter Amount"
              required
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              isInvalid={errors?.amount && errors?.amount?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.amount?.[0]}
            </Form.Control.Feedback>
          </Form.Group>

          <Button className="btn btn-primary mt-4" onClick={handleRecharge}>
            <div
              style={{
                transform: !isLoading ? "translateX(-1.5rem)" : "translateX(0)",
              }}
            >
              <div
                className="spinner-border text-warning loadding-spinner mr-2"
                style={{
                  opacity: isLoading ? 100 : 0,
                }}
                role="status"
              >
                <span className="visually-hidden"></span>
              </div>

              <span>Submit</span>
            </div>
          </Button>
        </PopupWrapper>

        <div className="m-7" style={{ marginBottom: "100px" }}></div>
      </Row>

      <PopupWrapper
        title="Delete"
        show={showCardDelete}
        setShow={setShowCardDelete}
      >
        <p className="text-danger">
          {" "}
          Are you sure you want to remove this card?
        </p>

        <CommonButton
          isLoading={isCardLoading}
          text="Remove"
          onSubmit={() => {
            handleDeletePaymentMethods(deleteID);
          }}
        />
      </PopupWrapper>

      <Toaster
        status={tosterStatus}
        message={tosterMassage}
        show={showToster}
        setShow={setShowToster}
      />
    </Container>
  );
};

export default Payment;
